<template>
  <div class="space-y-5">
    <div class="flex items-center justify-between flex-row-reverse lg:flex-row">
      <h1 class="page_title">{{ $route.meta.title }}</h1>
    </div>

    <main v-if="is_loaded" class="bg-white rounded-2xl w-full pt-4">
      <!-- 搜尋 -->
      <section class="px-4 grid grid-cols-2 gap-2 lg:grid-cols-8">
        <vs-input class="lg:col-span-2" v-model="keyword" @blur="search" @keypress.enter="search" placeholder="搜尋" />
        <vs-switch v-model="roomcountSwitch" class="w-28" @change="getRoomCount">
          <template #on>
            物件數遞減
          </template>
          <template #off>
            物件數遞增
          </template>
        </vs-switch>
      </section>

      <section class="text-sm overflow-x-auto">
        <div class="text-gray-text_dark font-medium flex items-center px-4 h-14 w-min sm:w-full">
          <!-- Head -->
          <transition name="fade">
            <div class="title_section" key="2">
              <span class="w-12"></span>
              <span class="w-20">個人照</span>
              <span class="w-28">員工編號</span>
              <span class="w-28">姓名</span>
              <span class="w-28">業務身分</span>
              <span class="w-28">物件數</span>
            </div>
          </transition>
        </div>

        <!-- Body -->
        <div class="flex flex-col divide-y divide-gray-background text-gray-text_light w-min sm:w-full">
          <div v-for="item in data" :key="item.id">
            <div
              class="flex items-center h-14 hover:bg-gray-background transition-colors cursor-pointer px-4 relative group"
            >
              <div class="title_section">
                <div class="w-12"><span class="ml-2">{{ item.no }}</span></div>
                <div class="w-20">
                  <div class="w-10 h-10 items-center justify-center flex border border-gray-border rounded-lg overflow-hidden">
                    <img v-if="item.photo" :src="item.photo" class="w-full h-full object-cover" />
                    <svg-icon v-else icon-class="account"></svg-icon>
                  </div>
                </div>
                <div class="w-28"><span style="word-break: break-all">{{ item.number }}</span></div>
                <div class="w-28"><span style="word-break: break-all">{{ item.name }}</span></div>
                <div class="w-28">
                  <span v-if="item.salesman_status" class="text-green-900 font-bold">是</span>
                  <span v-else>否</span>
                </div>
                <div class="w-28"><span style="word-break: break-all">{{ item.room_num | currency }} 件</span></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>

    <!-- 骨架屏區塊 -->
    <main v-else class="bg-white rounded-2xl w-full pt-4">
      <section class="flex space-x-3 px-4 mb-2">
        <skeleton />
        <skeleton class="sm:flex-shrink-0" :width="200" />
      </section>
      <section class="text-sm overflow-x-auto">
        <div v-for="(item, index) in 3" :key="index" class="text-gray-text_dark font-medium flex items-center px-4 h-14 w-full">
          <div class="checkbox_section">
            <skeleton :width="23" :height="23" />
          </div>
          <skeleton width="100%" height="23px" />
        </div>
      </section>
    </main>

    <!-- pagination -->
    <div v-if="pagination.last_page > 1" class="flex flex-col items-center space-y-2">
      <vs-pagination @click.native="changePagination" not-arrows v-model="currentPage" :length="pagination.last_page" />
      <span class="text-xs text-gray-400">總共 {{ pagination.total }} 位</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'sales_report',
  components: {
  },
  data() {
    return {
      title: '業務報表',
      is_loaded: false,
      keyword: '', //搜尋
      roomcountSwitch: true,
      data: [],
      currentPage: 1, //分頁
      pagination: {}, //分頁
    }
  },
  computed: {
    _selectedToStr() {
      return this.selected.toString()
    },
  },
  watch: {
  },
  created() {
    let routeQuery = JSON.parse(JSON.stringify(this.$route.query))
    if(this.$route.query != ""){
      let arr = [];
      for(let key in routeQuery){
        if(routeQuery[key]!= ""){
          arr.push([key,routeQuery[key]])
        }
      }
      this.loadData(arr)
    }else{
      this.loadData([['page', 1],['keyword', '']])
    }
  },
  methods: {
    // 讀取列表
    loadData(arr) {
      // 搜尋-------------------
      let routeQuery = JSON.parse(JSON.stringify(this.$route.query))
      if (arr && arr.length > 0) {
        arr.forEach((item) => {
          if(item[1] != ''){
            routeQuery[item[0]] = item[1]
            if(item[0] == 'page') this.currentPage = item[1]
            else if(item[0] == 'keyword') this.keyword = item[1]
          } else{
            delete routeQuery[item[0]]
          } 
        })
      }
      this.$router.replace({ query: routeQuery }).catch(err => {err})
      // End搜尋-------------------
      let sort = ''
      if(this.roomcountSwitch) sort = 'counts-desc'
      else sort = 'counts-asc'
      this.$axios({
        url: 'backstage/reports/sales_reports',
        method: 'get',
        params: {
          page: routeQuery.page ? routeQuery.page : 1,
          q: routeQuery.keyword ? routeQuery.keyword : '',
          sort: sort
        },
      }).then((res) => {
        if (res.data.status) {
          this.is_loaded = true
          this.data = res.data.data
          this.pagination = res.data.meta
          //加入流水號
          for(let key in this.data) {
            this.data[key]['id'] = this.data[key]['id'].toString();
            this.data[key]['no'] = ((this.pagination.current_page-1)*this.pagination.per_page) + 1 + parseInt(key)
          }
        } else {
          this.$myVS.openNoti('ERROR', res.data.Message, 'danger', 3000)
        }
      })
    },
    // 搜尋
    search(e) {
      if (this.keyword === this.keywordBAK) return
      this.currentPage = 1
      this.loadData([
        ['page', 1],
        ['keyword', this.keyword],
      ])
      this.keywordBAK = this.keyword
    },
    // 分頁
    changePagination() {
      this.loadData([['page', this.currentPage],['keyword', this.keyword]])
    },
    getRoomCount() {
      this.loadData([['page', this.currentPage],['keyword', this.keyword]])
    }
  }
}
</script>

<style lang="scss" scoped>
.checkbox_section {
  @apply w-28 flex-shrink-0;
}

.title_section {
  @apply flex items-center w-min sm:w-full;
  .title {
    @apply w-48 sm:flex-grow;
  }
}

.filter_section {
  @apply px-4 flex flex-wrap;

  .filter_box {
    @apply bg-gray-200 rounded-xl h-10 px-3 space-x-1
    flex items-center justify-center mr-2 mb-2;
  }
}
</style>
